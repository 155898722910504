import { createRouter, createWebHistory } from 'vue-router';
const routes = [
    {
        path: '/approval/qrf',
        component: () => import('@/layout/ApprovalSPRFLayout.vue'),
        redirect: '/approval/qrf/menunggu-approval',
        children: [
            {
                path: 'menunggu-approval',
                name: 'WaitingBillingFormView',
                component: () => import('@/views/WaitingApprovalView.vue'),
            },
            {
                path: 'selesai-approval',
                name: 'CompletedLoadPOView',
                component: () => import('@/views/CompletedApprovalView.vue'),
            },
            {
                path: 'approval-level-sebelumnya',
                name: 'PreviousLevelApprovalView',
                component: () => import('@/views/PreviousLevelApprovalView.vue'),
            },
        ],
    },
    {
        path: '/approval/qrf/detail/:id',
        name: 'DetailBillingFormView',
        component: () => import('@/views/DetailApprovalView.vue'),
    },
];
const router = createRouter({
    history: createWebHistory((import.meta.env || process.env).BASE_URL),
    routes,
});
export default router;
